import { cond, equals, T, test, anyPass } from 'ramda';

import { randomInteger } from '../utils';

import { DOT_SIZES, SIZE_FACTOR, SWIM_STEP, CORNER_STEP, OFFSET_FACTOR } from './consts';

export const getInitPosition = (initPosition, width, height) =>
  cond([
    [equals('top'), () => [0, randomInteger(width * DOT_SIZES[0], width * DOT_SIZES[1])]],
    [equals('right'), () => [randomInteger(height * DOT_SIZES[0], height * DOT_SIZES[1]), width]],
    [equals('bottom'), () => [height, randomInteger(width * DOT_SIZES[0], width * DOT_SIZES[1])]],
    [equals('left'), () => [randomInteger(height * DOT_SIZES[0], height * DOT_SIZES[1]), 0]],
    [equals('top-right'), () => [0, width]],
    [equals('top-left'), () => [0, 0]],
    [equals('bottom-right'), () => [height, width]],
    [equals('bottom-left'), () => [height, 0]],
    [T, () => [0, 0]],
  ])(initPosition);

export const getSize = (id, amount, width, height) => {
  const fullSize = Math.min(width, height);
  const minSize = fullSize * DOT_SIZES[0];
  const maxSize = fullSize * DOT_SIZES[1];
  const step = Math.round((maxSize - minSize) / amount);

  return randomInteger(maxSize - (id + 1) * step, maxSize - id * step) / SIZE_FACTOR;
};

const calculatePosition = (corner, top, left) => [top + Math.sin(corner) * SWIM_STEP, left + Math.cos(corner) * SWIM_STEP];
const fixPosition = (top, left, offset, width, height) => {
  if (top < -offset && left < -offset) return [-offset, -offset];
  if (top < -offset) return [-offset, left];
  if (left < -offset) return [top, -offset];

  if (top > height + offset && left > width + offset) return [height + offset, width + offset];
  if (top > height + offset) return [height + offset, left];
  if (left > width + offset) return [top, width + offset];

  return [top, left];
};

export const getNextPosition = ({ id, currentCorner, corner, size, top, left, width, height, getFrame, iteration = 0 }) => {
  if (iteration > (4 * Math.PI) / CORNER_STEP) return { corner, x: left, y: top };

  const offset = size * OFFSET_FACTOR;
  const frame = typeof getFrame === 'function' && getFrame(width, offset);

  if (frame && left > frame[0] && left < frame[1]) {
    return { corner, x: left - frame[0] > frame[1] - left ? frame[1] : frame[0], y: top };
  }

  const [nextTop, nextLeft] =
    iteration > (2 * Math.PI + 0.1) / CORNER_STEP
      ? fixPosition(top, left, offset, width, height)
      : calculatePosition(corner, top, left);

  if (
    nextTop < -offset ||
    nextLeft < -offset ||
    nextTop > height + offset ||
    nextLeft > width + offset ||
    (frame && nextLeft > frame[0] && nextLeft < frame[1])
  ) {
    return getNextPosition({
      currentCorner,
      corner: corner + (id % 1 ? CORNER_STEP : -CORNER_STEP),
      size,
      top,
      left,
      width,
      height,
      iteration: iteration + 1,
    });
  }

  return { corner, x: nextLeft, y: nextTop };
};

export const throttle = (cb, ms) => {
  const data = { timer: null, args: null };

  return (...args) => {
    if (data.timer !== null) {
      data.args = args;

      return;
    }

    cb(...args);

    data.timer = setTimeout(() => {
      if (data.args) {
        cb(...data.args);

        data.args = null;
      }

      data.timer = null;
    }, ms);
  };
};

export const getFrame = (pathname, isOpenMenu) =>
  cond([
    [() => isOpenMenu, () => null],
    [
      anyPass([test(/^\/praxis.?/), test(/^\/kontakt.?/)]),
      () => (width, offset) => {
        if (width < 992) return [10 - offset, width - 10 + offset];
        if (width < 1531) return [25 - offset, width - 25 + offset];

        const margin = (width - 1480) / 2;

        return [margin - offset, width - margin + offset];
      },
    ],
    [
      test(/^\/team\/.+/),
      () => (width, offset) => {
        if (width < 992) return [10 - offset, width - 10 + offset];
        if (width < 1531) return [width / 2 - offset, width - 25 + offset];

        const margin = (width - 1480) / 2;

        return [width / 2 - offset, width - margin + offset];
      },
    ],
    [T, () => null],
  ])(pathname);
