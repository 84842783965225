import styled from 'styled-components';

export const Container = styled.footer`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 0 25px;
  @media (max-width: 991px) {
    height: 60px;
    padding: 0 10px;
  }
`;

export const Span = styled.span`
  font: var(--font-53);
  color: var(--color-grey13);
  text-transform: uppercase;
  @media (max-width: 991px) {
    font: var(--font-39);
  }
`;
