import { createGlobalStyle } from 'styled-components';

import opticianSansWoff from '@/assets/fonts/OpticianSans.woff';
import opticianSansWoff2 from '@/assets/fonts/OpticianSans.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: "OpticianSans";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${opticianSansWoff}) format("woff"), url(${opticianSansWoff2}) format("woff2");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 350px;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }
  body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    color: var(--color-black);
    background-color: var(--color-white);
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }
  #___gatsby, #gatsby-focus-wrapper  {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  input, textarea, button {
    background: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    appearance: none;
    -webkit-appearance: none;
  }
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
  :root {
    --color-white: #fff;
    --color-black: #000;
    --color-grey59: #595959;
    --color-grey13: #131413;
    --color-greyDE: #dedede;
    --color-greyD8: #d8d8d8;
    --color-purpleLink: #8800d2;
    --color-grey98-rgb: 152, 152, 152;

    --color-orange: #ff8000;
    --color-green: #91d425;
    --color-pink: #f08ee0;
    --color-purple: #c964fc;
    --color-blue: #4dccff;
    
    --font-53: 400 53px/1.11 "OpticianSans", sans-serif;
    --font-39: 400 39px/1.51 "OpticianSans", sans-serif;
    --font-35: 400 35px/normal "Work Sans", sans-serif;
    --font-33: 400 33px/normal "Work Sans", sans-serif;
    --font-20: 400 20px/normal "Work Sans", sans-serif;
    --font-16: 400 16px/normal "Work Sans", sans-serif;
  }
`;
