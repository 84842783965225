import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getLetters } from './utils';
import MenuButton from './MenuButton';
import Menu from './Menu';
import { Container, Span } from './styles';

const Header = ({ pathname, isOpenMenu, setOpenMenu }) => {
  const letters = useMemo(() => getLetters(pathname), [pathname]);

  return (
    <>
      <Container>
        {letters.map(({ key, letter }) => (
          <Span key={key}>{letter}</Span>
        ))}
      </Container>
      <MenuButton isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} />
      <Menu pathname={pathname} isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} />
    </>
  );
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
  isOpenMenu: PropTypes.bool.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
};

export default Header;
