import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Main = styled(motion.main).attrs(({ $isOpenMenu }) => ({
  animate: {
    opacity: $isOpenMenu ? 0 : 1,
  },
  transition: {
    duration: $isOpenMenu ? 0.05 : 0.3,
  },
  initial: false,
}))`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ $isTeamOverview }) => ($isTeamOverview ? 1920 : 1530)}px;
  max-height: ${({ $isOpenMenu }) => ($isOpenMenu ? 'calc(var(--vh, 1vh) * 100 - 140px)' : 'none')};
  margin: 0 auto;
  padding: 45px 25px 25px;
  @media (max-width: 991px) {
    max-height: ${({ $isOpenMenu }) => ($isOpenMenu ? 'calc(var(--vh, 1vh) * 100 - 120px)' : 'none')};
    padding: 20px 10px 10px;
  }
`;
