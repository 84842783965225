import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  position: fixed;
  inset: 0 0 auto auto;
  padding: 0 10px;
  z-index: 100;
  cursor: pointer;
  @media (max-width: 991px) {
    height: 60px;
    width: 60px;
    padding: 0 10px;
  }
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { rotate: $isOpen ? 180 : 0 },
  transition: { duration: 0.3, ease: [0.19, 1, 0.22, 1] },
  initial: false,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;
