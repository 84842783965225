import React, { useRef, useMemo, useCallback, useEffect } from 'react';
import { useMotionValue, animate } from 'framer-motion';
import PropTypes from 'prop-types';

import { randomInteger } from '../utils';

import { SWIM_STEP, SWIM_SPEED, INIT_DURATION } from './consts';
import { getInitPosition, getSize, getNextPosition, getFrame, throttle } from './utils';
import { Div } from './styles';

const Dot = ({ id, initPosition, color, amount, pathname, isOpenMenu }) => {
  const nextPosition = useRef(null);
  const animations = useMemo(() => ({ size: null, top: null, left: null }), []);
  const [innerWidth, innerHeight] = useMemo(
    () => (typeof window === 'undefined' ? [0, 0] : [window.innerWidth, window.innerHeight]),
    []
  );
  const init = useMemo(() => getInitPosition(initPosition, innerWidth, innerHeight), [initPosition, innerWidth, innerHeight]);
  const [size, top, left, angle, frame] = [
    useMotionValue(0),
    useMotionValue(init[0]),
    useMotionValue(init[1]),
    useMotionValue(randomInteger(0, 5)),
    useMotionValue(null),
  ];
  const swimDuration = useMemo(() => SWIM_STEP / randomInteger(...SWIM_SPEED), []);

  const stopAnimations = useCallback(() => {
    try {
      if (animations.size) animations.size.stop();
      if (animations.top) animations.top.stop();
      if (animations.left) animations.left.stop();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  }, [animations]);

  const swimAnimation = useCallback(
    async ({ corner, x, y }) => {
      angle.set(corner);
      animations.top = animate(top, y, { duration: swimDuration, ease: 'linear' });
      animations.left = animate(left, x, {
        duration: swimDuration,
        ease: 'linear',
        onComplete: () => nextPosition.current && swimAnimation(nextPosition.current),
      });

      Promise.resolve(
        getNextPosition({
          id,
          currentCorner: corner,
          corner,
          size: size.current,
          top: y,
          left: x,
          width: window.innerWidth,
          height: window.innerHeight,
          getFrame: frame.current,
        })
      ).then((value) => {
        nextPosition.current = value;
      });
    },
    [angle, animations, frame, id, left, size, swimDuration, top]
  );

  const initAnimationWithThrottle = useMemo(
    () =>
      throttle(async () => {
        stopAnimations();

        const initSize = getSize(id, amount, window.innerWidth, window.innerHeight);

        animations.size = animate(size, initSize, { duration: INIT_DURATION });

        frame.set(getFrame(pathname, isOpenMenu));
        nextPosition.current = getNextPosition({
          id,
          currentCorner: angle.current,
          corner: angle.current,
          size: size.current,
          top: top.current,
          left: left.current,
          width: window.innerWidth,
          height: window.innerHeight,
          getFrame: frame.current,
        });

        if (nextPosition.current) {
          swimAnimation(nextPosition.current);
        }
      }, 500),
    [amount, angle, animations, frame, id, isOpenMenu, left, pathname, size, stopAnimations, swimAnimation, top]
  );

  useEffect(() => {
    if (typeof window === 'undefined') return () => null;

    initAnimationWithThrottle();

    window.addEventListener('resize', initAnimationWithThrottle);

    return () => {
      window.removeEventListener('resize', initAnimationWithThrottle);
    };
  }, [initAnimationWithThrottle]);

  return <Div $color={color} style={{ width: size, height: size, top, left }} />;
};

Dot.propTypes = {
  id: PropTypes.number.isRequired,
  initPosition: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
  isOpenMenu: PropTypes.bool.isRequired,
};

export default Dot;
