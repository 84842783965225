import React from 'react';

import { LETTERS } from './consts';
import { Container, Span } from './styles';

const Footer = () => (
  <Container>
    {LETTERS.map(({ key, letter }) => (
      <Span key={key}>{letter}</Span>
    ))}
  </Container>
);

export default Footer;
