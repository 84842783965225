import React, { useState, useMemo, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import GlobalStyles from './GlobalStyles';
import BackgroundAnimation from './BackgroundAnimation';
import Header from './Header';
import Footer from './Footer';
import { Main } from './styles';

const Layout = ({ location, children }) => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const setCorrectVh = useCallback(() => {
    window.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }, []);
  const isTeamOverview = useMemo(() => /^\/team\/?$/.test(location.pathname), [location.pathname]);

  useLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    const html = window.document.querySelector('html');

    if (isOpenMenu) {
      window.scrollTo(0, 0);
      html.style.overflow = 'hidden';
    } else {
      html.style.overflow = 'auto';
    }
  }, [isOpenMenu]);

  useLayoutEffect(() => {
    if (typeof window === 'undefined') return () => null;

    setCorrectVh();
    window.addEventListener('resize', setCorrectVh);

    return () => window.removeEventListener('resize', setCorrectVh);
  }, [setCorrectVh]);

  return (
    <>
      <GlobalStyles />
      <BackgroundAnimation pathname={location.pathname} isOpenMenu={isOpenMenu} />
      {location.pathname === '/' ? (
        children
      ) : (
        <>
          <Header pathname={location.pathname} isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} />
          <Main $isOpenMenu={isOpenMenu} $isTeamOverview={isTeamOverview}>
            {children}
          </Main>
          <Footer key={isOpenMenu} />
        </>
      )}
    </>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
