import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ className }) => (
  <svg width="40" height="24" viewBox="0 0 40 24" className={className}>
    <g stroke="var(--color-grey13)" strokeWidth="4" strokeLinecap="round">
      <path d="M2 2 h36" />
      <path d="M2 12 h36" />
      <path d="M2 22 h36" />
    </g>
  </svg>
);

Hamburger.defaultProps = { className: null };
Hamburger.propTypes = { className: PropTypes.string };

export default Hamburger;
