import { remove } from 'ramda';

import { COLORS, PORTRAIT_POSITIONS, LANDSCAPE_POSITIONS } from './consts';

export const randomInteger = (min = 0, max = 0) => Math.floor(min + Math.random() * (max + 1 - min));

const shuffle = (arr) =>
  arr.reduce(
    (acc) => {
      const index = randomInteger(0, acc[1].length - 1);

      acc[0].push(acc[1][index]);
      acc[1] = remove(index, 1, acc[1]);

      return acc;
    },
    [[], [...arr]]
  )[0];

export const createDots = (width, height) => {
  const colors = shuffle(COLORS);
  const initPositions = shuffle(width < height ? PORTRAIT_POSITIONS : LANDSCAPE_POSITIONS);

  return colors.map((color, index) => ({
    id: index,
    initPosition: initPositions[index],
    color,
  }));
};
