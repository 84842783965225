import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import HamburgerIcon from '@/assets/svg/Hamburger';
import CrossIcon from '@/assets/svg/Cross';

import { Button, Wrapper } from './styles';

const MenuButton = ({ isOpenMenu, setOpenMenu }) => {
  const toggleMenu = useCallback(() => setOpenMenu(($) => !$), [setOpenMenu]);

  return (
    <Button type="button" onClick={toggleMenu} aria-label="Menu">
      <Wrapper $isOpen={isOpenMenu}>{isOpenMenu ? <CrossIcon /> : <HamburgerIcon />}</Wrapper>
    </Button>
  );
};

MenuButton.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
};

export default MenuButton;
