import { test } from 'ramda';

export const MENU = [
  {
    url: '/praxis/',
    name: 'Praxis',
    checkActive: test(/^\/praxis.?/),
  },
  {
    url: '/team/',
    name: 'Team',
    checkActive: test(/^\/team\/$/),
  },
  {
    url: '/kontakt/',
    name: 'Kontakt',
    checkActive: test(/^\/kontakt.?/),
  },
  {
    isExtern: true,
    url: 'https://augenmobil.ch/',
    name: 'Augenmobil',
  },
];
