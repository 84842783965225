import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Div = styled(motion.div)`
  position: absolute;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  filter: blur(28px);
  transform: translate3d(-50%, -50%, 0);
  will-change: width, height, top, left;
`;
