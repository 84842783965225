import { cond, test, T } from 'ramda';

import { splitWord } from '@/utils';

const mapping = {
  index: splitWord('augenpraxis'),
  praxis: splitWord('praxis'),
  team: splitWord('team'),
  contact: splitWord('kontakt'),
};

export const getLetters = cond([
  [test(/^\/praxis.?/), () => mapping.praxis],
  [test(/^\/team.?/), () => mapping.team],
  [test(/^\/kontakt.?/), () => mapping.contact],
  [T, () => mapping.index],
]);
