import React from 'react';
import PropTypes from 'prop-types';

const Cross = ({ className }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" className={className}>
    <g stroke="var(--color-grey13)" strokeWidth="4" strokeLinecap="round">
      <path d="M2 2 L34 34" />
      <path d="M34 2 L2 34" />
    </g>
  </svg>
);

Cross.defaultProps = { className: null };
Cross.propTypes = { className: PropTypes.string };

export default Cross;
