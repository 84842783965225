import React, { useState, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { createDots } from './utils';
import Dot from './Dot';
import { Container } from './styles';

const BackgroundAnimation = ({ pathname, isOpenMenu }) => {
  const [allowed, setAllowed] = useState(false);
  const dots = useMemo(() => (typeof window === 'undefined' ? [] : createDots(window.innerWidth, window.innerHeight)), []);

  useLayoutEffect(() => {
    setAllowed(typeof window !== 'undefined');
  }, []);

  if (!allowed) return null;

  return (
    <Container>
      {dots.map(({ id, initPosition, color }) => (
        <Dot
          key={id}
          id={id}
          initPosition={initPosition}
          color={color}
          amount={dots.length}
          pathname={pathname}
          isOpenMenu={isOpenMenu}
        />
      ))}
    </Container>
  );
};

BackgroundAnimation.propTypes = {
  pathname: PropTypes.string.isRequired,
  isOpenMenu: PropTypes.bool.isRequired,
};

export default BackgroundAnimation;
