import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Nav = styled(motion.nav).attrs(({ $isOpen }) => ({
  animate: {
    left: $isOpen ? '0%' : '100%',
    opacity: $isOpen ? 1 : 0,
  },
  transition: { duration: $isOpen ? 0.3 : 0.05 },
  initial: false,
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  inset: 70px 0;
  z-index: 99;
  overflow: auto;
  @media (max-width: 991px) {
    inset: 60px 0;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 991px) {
    padding: 0 10px;
  }
`;

export const LinkWrap = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px 2px;
  align-items: center;
  width: 100%;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const linkStyle = ({ $isActive }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 63px;
  width: 100%;
  border-radius: 31.5px;
  background-color: ${$isActive ? 'var(--color-black)' : 'rgba(152, 152, 152, 0.32)'};
  font: var(--font-33);
  color: ${$isActive ? 'var(--color-white)' : 'var(--color-black)'};
  text-decoration: none;
  text-transform: uppercase;
  &:active {
    background-color: var(--color-black);
    color: var(--color-white);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
`;

export const NavLink = styled(Link)`
  ${linkStyle}
`;

export const ExternLink = styled.a`
  ${linkStyle}
`;

export const CloseButton = styled.button`
  flex: 0 0 auto;
  display: none;
  justify-content: center;
  align-items: center;
  height: 63px;
  width: 100%;
  border-radius: 31.5px;
  background-color: rgba(152, 152, 152, 0.32);
  font: var(--font-33);
  color: var(--color-black);
  text-transform: uppercase;
  &:active {
    background-color: var(--color-black);
    color: var(--color-white);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
  @media (max-width: 991px) {
    display: flex;
  }
`;
