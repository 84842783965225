import React, { useRef, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { MENU } from './consts';
import { Nav, Wrapper, LinkWrap, NavLink, ExternLink, CloseButton } from './styles';

const Menu = ({ pathname, isOpenMenu, setOpenMenu }) => {
  const ref = useRef(null);
  const closeMenu = useCallback(() => {
    if (typeof setOpenMenu === 'function') {
      setOpenMenu(false);
    }
  }, [setOpenMenu]);

  useLayoutEffect(() => {
    if (isOpenMenu && typeof setOpenMenu === 'function') {
      ref.current?.scrollTo(0, 0);
    }
  }, [isOpenMenu, setOpenMenu]);

  return (
    <Nav ref={ref} $isOpen={isOpenMenu}>
      <Wrapper>
        <LinkWrap>
          {MENU.map(({ isExtern, url, name, checkActive }) =>
            isExtern ? (
              <ExternLink key={url} href={url} onClick={closeMenu} target="_blank" rel="noopener noreferrer">
                {name}
              </ExternLink>
            ) : (
              <NavLink key={url} to={url} onClick={closeMenu} $isActive={checkActive(pathname)}>
                {name}
              </NavLink>
            )
          )}
        </LinkWrap>
        {typeof setOpenMenu === 'function' && (
          <CloseButton type="button" onClick={closeMenu}>
            Schliessen
          </CloseButton>
        )}
      </Wrapper>
    </Nav>
  );
};

Menu.defaultProps = {
  isOpenMenu: false,
  setOpenMenu: null,
};
Menu.propTypes = {
  pathname: PropTypes.string.isRequired,
  isOpenMenu: PropTypes.bool,
  setOpenMenu: PropTypes.func,
};

export default Menu;
